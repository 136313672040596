.tlc-container-wrapper-Diamond {
    .tlc-sign-header {
        .notice-wrap {
            .marquee-container-wrapper {
                .marquee-title {
                    span {
                        color: #fff !important;
                    }
                }

                .marquee-container {
                    .tlc-notice-wrap {
                        background-color: transparent !important;
                        color: #fff;
                    }
                }
            }
        }

        .login-wrap {
            .good-greeting {
                color: #fff;
            }
        }
    }

    // Top tab
    .diamond-club-tob-tab {
        .ant-tabs-nav-container {
            .ant-tabs-nav-wrap {
                .ant-tabs-nav-scroll {
                    border-radius: 0px;
                    border-bottom: 1px solid #e0e0e0;

                    .ant-tabs-nav {
                        position: initial;

                        .ant-tabs-tab:hover {
                            color: #c7c7c7;
                        }

                        .ant-tabs-tab-active {
                            color: #c5af5f !important;
                        }
                    }
                }
            }
        }
    }

    .tlc-about {
        padding: 0;
    }

    .ant-tabs-bar {
        margin-bottom: 60px;
    }
    //general setting
    .ant-tabs.border-tabs {
        .ant-tabs-nav-container::after {
            display: block;
            height: 2px;
            background: white;
        }

        .ant-tabs-tab-active {
            color: #c5af5f !important;
            background-color: transparent !important;
        }
        .ant-tabs-ink-bar {
            bottom: 0;
            background-color: #c5af5f;
        }
    }
    caption,
    .FaqTitle,
    h3 {
        color: #c5af5f;
        caption-side: top;
        font-size: 16px;
        padding: 0 0 24px 16px;
        font-weight: 700;
    }
    .ant-tabs {
        max-width: 1000px;
        margin: 0 auto;
    }

    // ### 會員等級Tab內容 ###
    .ViPLevel {
        min-height: 100vh;
        position: relative;

        caption,
        h3 {
            padding: 0 0 24px 2px;
        }
        .swiper-wrapper {
            display: flex;
            width: 100%;
        }

        .normal-member-box {
            margin-bottom: 60px;

            .diamond-slider {
                margin-bottom: 24px;
            }

            .rule {
                color: #f6f6f6;
                font-size: 12px;
                table tbody tr:not(:first-child) {
                    display: block;
                    margin-bottom: 10px;
                }
            }
        }

        .vip-member-box {
            margin-bottom: 60px;
        }

        //VIP專享權限box 改Hardcode 先保留
        // .tlcVIP_special {
        //   > table {
        //     margin-bottom: 113px;

        //     //VIP權益table範圍
        //     > tbody > tr > td > table {
        //       background-color: #2d2f33;
        //       border-radius: 10px;
        //       width: 50%;
        //       color: #aba79d;
        //       font-size: 14px;
        //       text-align: center;
        //       margin-bottom: 24.29px;

        //       tr {
        //         border-bottom: 1px solid #393b40;
        //       }

        //       //第一列
        //       tr:nth-child(1) {
        //         color: #d6d1c2;
        //         font-size: 16px;
        //         font-weight: 700;

        //         td {
        //           width: calc(50% / 4);
        //         }
        //       }

        //       //第一行
        //       tr td:nth-child(1) {
        //         padding-left: 28.03px;
        //         text-align: left;
        //       }
        //       //所有欄位
        //       td {
        //         padding: 16.5px 0;
        //       }
        //     }

        //     //權益釋疑條目
        //     > tbody > tr:not(:first-child) {
        //       display: block;
        //       color: #f6f6f6;
        //       font-weight: 300;
        //       margin-bottom: 12px;
        //     }
        //   }
        // }

        .vip-right-table {
            margin-bottom: 24px;
        }

        .vip-right-condition {
            margin-bottom: 100px;
        }
    }

    // ### 詳情Tab內容 ###
    .vip-detail-container {
        padding: 40px 0;
        .score-box {
            width: 100%;
            display: flex;
            gap: 52px;
            margin-bottom: 42px;

            & > div {
                width: 100%;
            }

            // .score-table {
            //   color: #aba79d;
            //   width: 100%;

            //   > table {
            //     width: 100%;

            //     tbody tr td table {
            //       width: 100%;
            //       background-color: #2d2f33;
            //       border-radius: 10px;
            //       //整個table範圍
            //       tbody {
            //         tr {
            //           border-bottom: 1px solid #393b40;
            //           // 所有欄位
            //           td {
            //             font-size: 14px;
            //             padding: 16.58px 0;
            //             text-align: center;
            //             // 第一行
            //             &:nth-child(1) {
            //               text-align: left;
            //               padding: 16.58px 34.78px;
            //             }
            //           }
            //           // 第一列
            //           &:nth-child(1) {
            //             color: #d6d1c2;
            //             font-size: 15px;
            //             font-weight: 700;
            //             td {
            //               width: 170px;
            //             }
            //           }
            //         }
            //       }
            //     }
            //   }
            // }
        }

        .faq-box {
            color: white;
            margin-bottom: 42px;

            .rc-collapse {
                background-color: #17191c;
                border: 0;
                width: 100%;
                display: grid;
                grid-template-columns: 1fr 1fr;
                column-gap: 52px;
                row-gap: 10px;

                .rc-collapse-item {
                    background-color: #2d2f33;
                    padding: 13px 15px;
                    border-radius: 10px;
                    width: 100%;
                    height: fit-content;
                    .rc-collapse-header {
                        color: #d6d1c2;
                        font-size: 14px;
                        line-height: 19px;
                        cursor: default;
                        .rc-collapse-expand-icon {
                            cursor: pointer;
                            svg {
                                color: #fff;
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }

                    .rc-collapse-content-box {
                        table {
                            width: fit-content !important;
                            max-width: 100%;
                        }

                        .FaqContent {
                            background-color: #2d2f33;
                            padding: 20px 24px 0px 0px;
                            color: #d6d1c2;
                            font-weight: 300;

                            * {
                                max-width: 100%;
                            }

                            p {
                                font-size: 12px;
                                line-height: 20px;
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }

        .rule-box {
            .rule-list {
                li {
                    color: #aba79d;
                }
            }
            // tbody {
            //   display: block;
            //   padding-left: 16px;
            //   color: #aba79d;
            //   font-size: 12px;

            //   tr {
            //     td:nth-child(1) {
            //       display: flex;
            //       align-items: flex-start;
            //       padding-right: 8px;
            //     }
            //   }
            // }
        }
    }
}
