.footer-platform-wrap {
    background-color: #eeeff0;
    padding-top: 36px;
    padding-bottom: 36px;
    margin-top: 70px;
    background-image: linear-gradient(0deg, #eeeff0, #f8f9fa);
    .ant-select {
        font-size: 12px;
    }
    .ant-select-selector {
        margin-top: 2px;
        border-radius: 6px !important;
        padding-left: 34px !important;
        background-image: url(/cn/img/home/sprite20220315.png);
        background-repeat: no-repeat;
        background-position: -285px -288px;
    }
    h5 {
        color: #666666;
        font-size: 12px;
        font-weight: bold;
        margin-bottom: 10px;
    }
    .footer-platform {
        padding-bottom: 20px;
        margin-bottom: 30px;
        border-bottom: 1px solid #cccccc;
    }
    .icon-list {
        line-height: 26px;
        ._set_{
            width: 100%;
            height: 100%;
        }

    }
    .icon-item {
        width: 26px;
        height: 26px;
        margin-right: 10px;
        vertical-align: middle;
        display: inline-block;
        background-image: url(/cn/img/home/tlc-footer-sprite.png);
        background-size: (540px/2.96) auto;
        background-repeat: no-repeat;
        cursor: pointer;
        &._1 {
            background-position: -(139px/2.96) -(2585px/2.96);
            &:hover {
                background-position: -(334px/2.96) -(2585px/2.96);
            }
        }
        &._2 {
            background-position: -(139px/2.96) -(2672px/2.96);
            &:hover {
                background-position: -(334px/2.96) -(2672px/2.96);
            }
        }
        &._3 {
            background-position: -(139px/2.96) -(2760px/2.96);
            &:hover {
                background-position: -(335px/2.96) -(2760px/2.96);
            }
        }
        &._4 {
            background-position: -(139px/2.96) -(2847px/2.96);
            &:hover {
                background-position: -(335px/2.96) -(2847px/2.96);
            }
        }
        &._5 {
            background-position: -(139px/2.96) -(2935px/2.96);
            &:hover {
                background-position: -(334px/2.96) -(2935px/2.96);
            }
        }
        &._6 {
            width: 46px;
            height: 14px;
            background-position: -(113px/2.96) -(3040px/2.96);
            &:hover {
                background-position: -(311px/2.96) -(3040px/2.96);
            }
        }
        &._7 {
            background-position: -(139px/2.96) -(2585px/2.96);
            &:hover {
                background-position: -(334px/2.96) -(2585px/2.96);
            }
        }
        &._9 {
            background-position: -(139px/2.96) -(3172px/2.96);
            &:hover {
                background-position: -(337px/2.96) -(3172px/2.96);
            }
        }
        &._10 {
            width: 24px;
            height: 24px;
            background-position: -(137px/2.96) -(3100px/2.96);
            &:hover {
                background-position: -(335px/2.96) -(3100px/2.96);
            }
        }
        &._11 {
            width: 126px;
            height: 26px;
            background-size: (540px/1.3) auto;
            background-position: -(89px/1.3) -(3346px/1.3);
            &:hover {
                background-position: -(287px/1.3) -(3346px/1.3);
            }
        }
    }
    .icon-item2 {
        width: 30px;
        margin-right: 10px;
        height: 30px;
        vertical-align: middle;
        display: inline-block;
        background-image: url(/cn/img/home/footer-sprite.png);
        background-repeat: no-repeat;
        cursor: pointer;
        &._1 {
            background-size: (544px/2.46) auto;
            background-position: -(381px/2.46) -(104px/2.46);
            &:hover {
                background-position: -(381px/2.46) -(10px/2.46);
            }
        }
        &._2 {
            background-size: (544px/2.46) auto;
            background-position: -(10px/2.46) -(114px/2.46);
            &:hover {
                background-position: -(192px/2.46) -(10px/2.46);
            }
        }
        &._3 {
            background-size: (544px/2.46) auto;
            background-position: -(198px/2.46) -(209px/2.46);
            &:hover {
                background-position: -(104px/2.46) -(209px/2.46);
            }
        }
        &._4 {
            background-size: (544px/2.46) auto;
            background-position: -(10px/2.46) -(209px/2.46);
            &:hover {
                background-position: -(287px/2.46) -(104px/2.46);
            }
        }
        &._5 {
            background-size: (544px/2.46) auto;
            background-position: -(287px/2.46) -(10px/2.46);
            &:hover {
                background-position: -(105px/2.46) -(114px/2.46);
            }
        }
        &._6 {
            margin-top: 0.4rem;
            width: 64px;
            height: 17px;
            background-size: (544px/1.8) auto;
            background-position: -(230px/1.8) -(303px/1.8);
            &:hover {
                background-position: -(95px/1.8) -(303px/1.8);
            }
        }
        &._7 {
            background-size: (544px/1.8) auto;
            background-position: (-84px/1.8) (-388px/1.8);
            &:hover {
                background-position: (-475px/1.8) (-186px/1.8);
            }
        }
        &._8 {
            background-size: (544px/1.8) auto;
            background-position: -(475px/1.8) -(260px/1.8);
            &:hover {
                background-position: -(10px/1.8) -(388px/1.8);
            }
        }
        &._9 {
            height: 33px;
            background-size: (544px/2.06) auto;
            background-position: -(199px/2.06) -(114px/2.06);
            &:hover {
                background-position: -(381px/2.06) -(198px/2.06);
            }
        }
        &._10 {
            background-size: (544px/2.16) auto;
            background-position: -(10px/2.16) -(303px/2.16);
            &:hover {
                background-position: -(292px/2.16) -(209px/2.16);
            }
        }
        &._11 {
            width: 126px;
            height: 26px;
            background-size: (544px/1.3) auto;
            background-position: -(10px/1.3) -(62px/1.3);
            &:hover {
                background-position: -(10px/1.3) -(10px/1.3);
            }
        }
        &._12 {
            width: 26px;
            background-size: (544px/2.3) auto;
            background-position: -(475px/2.3) -(98px/2.3);
            &:hover {
                background-position: -(475px/2.3) -(10px/2.3);
            }
        }
    }

    .f1-support {
        line-height: 40px;
        margin: 0;
        .support-type {
            display: inline-block;
            background-image: url(/cn/img/home/tlc-footer-sprite.png);
            background-size: (540px/2.1) auto;
            background-repeat: no-repeat;
            width: 120px;
            height: 40px;
            margin-right: 4px;
            margin-bottom: 14px;
            vertical-align: middle;
            &._1 {
                height: (60px/2.1);
                background-position: (-8px/2.1) (-1028px/2.1);
                &:hover {
                    background-position: (-279px/2.1) (-1028px/2.1);
                }
            }
            &._2 {
                height: (50px/2.1);
                background-position: (-8px/2.1) (-482px/2.1);
                &:hover {
                    background-position: (-277px/2.1) (-482px/2.1);
                }
            }
            &._3 {
                height: (60px/2.1);
                background-position: (-8px/2.1) (-566px/2.1);
                &:hover {
                    background-position: (-278px/2.1) (-566px/2.1);
                }
            }
            &._4 {
                height: (70px/2.1);
                background-position: (-8px/2.1) (-656px/2.1);
                &:hover {
                    background-position: (-279px/2.1) (-656px/2.1);
                }
            }
            &._5 {
                height: (50px/2.1);
                background-position: (-8px/2.1) (-757px/2.1);
                &:hover {
                    background-position: (-279px/2.1) (-757px/2.1);
                }
            }
            &._6 {
                height: (80px/2.1);
                background-position: (-8px/2.1) (-838px/2.1);
                &:hover {
                    background-position: (-277px/2.1) (-839px/2.1);
                }
            }
            &._7 {
                height: (60px/2.1);
                background-position: (-8px/2.1) (-387px/2.1);
                &:hover {
                    background-position: (-279px/2.1) (-387px/2.1);
                }
            }
            &._8 {
                height: (76px/2.1);
                background-position: (-8px/2.1) (-932px/2.1);
                &:hover {
                    background-position: (-279px/2.1) (-932px/2.1);
                }
            }
            &._9 {
                height: (70px/2.1);
                background-position: (-8px/2.1) (-1305px/2.1);
                &:hover {
                    background-position: (-279px/2.1) (-1305px/2.1);
                }
            }
            &._10 {
                height: (80px/2.1);
                background-position: (-8px/2.1) (-1386px/2.1);
                &:hover {
                    background-position: (-279px/2.1) (-1386px/2.1);
                }
            }
            &._11 {
                height: (50px/2.1);
                background-position: (-8px/2.1) (-1583px/2.1);
                &:hover {
                    background-position: (-277px/2.1) (-1583px/2.1);
                }
            }
            &._12 {
                height: (88px/2.1);
                background-position: (-8px/2.1) (-2119px/2.1);
                &:hover {
                    background-position: (-278px/2.1) (-2119px/2.1);
                }
            }
            &._13 {
                height: (66px/2.1);
                background-position: (-6px/2.1) (-2345px/2.1);
                &:hover {
                    background-position: (-265px/2.1) (-2345px/2.1);
                }
            }
            &._14 {
                height: (60px/2.1);
                background-position: (-8px/2.1) (-2045px/2.1);
                &:hover {
                    background-position: (-278px/2.1) (-2045px/2.1);
                }
            }
        }
    }
}
.footer-copyright-wrap {
    background-color: #00a6ff;
    font-size: 12px;
    color: #fff;
    padding: 20px 0;
    .copyright-picture {
        padding: 10px 0;
        img {
            width: 122px;
        }
    }
    .copyright-article {
        padding: 16px 0;
    }
    .copyright-content {
        margin: 0;
        text-align: center;
        p {
            margin: 0;
        }
    }
}

// Footer Select ����ѡ���
.footer-small-drop {
    .ant-select-dropdown {
        padding: 0;
    }
    .ant-select-item {
        min-height: 22px;
        font-size: 12px;
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        padding-top: 0;
        padding-bottom: 0;
    }
}

// TLC Footer ��ά�뵯��
.ant-modal.tlc-wechat-modal._white .ant-modal-content {
    background-color: #fff;
    color: #000;
    .ant-modal-close {
        color: #000;
    }
}
.ant-modal.tlc-wechat-modal .ant-modal-content {
    background-color: #000;
    color: #fff;
    .ant-modal-body {
        padding: 50px;
        & > img {
            width: 100%;
            margin-top: 10px;
        }
        & > p {
            margin-bottom: 0.2em;
        }
    }
    .ant-modal-close {
        color: #fff;
    }
}
.ant-popover.footer-popover {
    .ant-popover-inner {
        border-radius: 4px;
    }
    .ant-popover-inner-content {
        padding: 6px 10px;
    }
}
