.left-next,
.right-next {
    top: 0;
    bottom: 0;
    width: 36px;
    height: 64px;
    margin: auto;
    position: absolute;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    // background-color: #676767;
    z-index: 1;
    img {
        width: 32px;
        height: 64px;
    }
}

.left-next {
    left: -1px;
}

.right-next {
    right: -1px;
}

.digital-coin-withdraw-lesson {
    .left-next .arrowLeftRight {
        border-radius: 0 4px 4px 0;
        margin-left:46px;
    }
    .right-next .arrowLeftRight {
        border-radius: 4px 0 0 4px;
        margin-right:46px;
    }
}
.crypto-lesson-wrap {
    position: relative;
    width: 100%;
    height: 400px;
    border-radius: 8px;
    background-position: 50% 100%;
    background-repeat: no-repeat;
    background-size: 100%;
    &.digital-ls-1 {
        background-image: url(/cn/img/withdrawlesson/digital_1.jpg);
    }
    &.digital-ls-2 {
        background-image: url(/cn/img/withdrawlesson/digital_2.jpg);
    }
    &.digital-ls-3 {
        background-image: url(/cn/img/withdrawlesson/digital_3.jpg);
    }
    &.digital-ls-4 {
        background-image: url(/cn/img/withdrawlesson/digital_4.jpg);
    }

    &.withdraw-ls-1 {
        background-image: url(/cn/img/withdrawlesson/withdrawal_1.jpg);
    }
    &.withdraw-ls-2 {
        background-image: url(/cn/img/withdrawlesson/withdrawal_2.jpg);
    }
    &.withdraw-ls-3 {
        background-image: url(/cn/img/withdrawlesson/withdrawal_3.jpg);
    }
    &.withdraw-ls-4 {
        background-image: url(/cn/img/withdrawlesson/withdrawal_4.jpg);
    }

    &.address-ls-1 {
        background-image: url(/cn/img/withdrawlesson/address_1.jpg);
    }
    &.address-ls-2 {
        background-image: url(/cn/img/withdrawlesson/address_2.jpg);
    }
}

.ant-tabs-tab {
    color: #999999;
}

.ant-tabs-nav .ant-tabs-tab-active {
    font-weight: 1000 !important;
    color: #222222 !important;
    font-size: 14px !important;
    font-family: Microsoft YaHei;
}

.crypto-lesson-body {
    margin-left: 6rem;
    margin-right: 6rem;
}

.deposit-comment-title {
    text-align: left;
    font-weight: 1000;
    font-size: 14px;
    margin-top: 18px;
    margin-bottom: 7px;
    font-family: Microsoft YaHei;
    text-shadow: 0.2px 0.2px, 0.2px 0.2px, 0.2px 0.2px;
    margin-left: 10px;
}

.deposit-comment-content {
    text-align: left;
    font-size: 12px;
}

.slick-dots .custom-Carousel {
    bottom: -5px !important;
    z-index: 999;
}

.ant-carousel .custom-Carousel .slick-dots li.slick-active {
    background: transparent;
}

.ant-carousel .custom-Carousel .slick-dots li.slick-active button {
    background: #000000bf;
    border-radius: 8px;
    height: 8px;
    width: 8px;
}

.ant-carousel .custom-Carousel .slick-dots li {
    background: transparent;
}

.ant-carousel .custom-Carousel .slick-dots li button {
    background: #00000040;
    border-radius: 50%;
    height: 8px;
    width: 8px;
}

.arrowLeftRight {
    background-color: #00000040;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px;
}

.comment-title {
    text-align: center;
    color: #f92d2d;
    font-size: 18px;
    font-weight: 1000;
    font-family: Microsoft YaHei;
    padding-top: 2rem;
}

.comment-content {
    text-align: left;
    color: #000000;
    font-weight: 500;
    font-family: Microsoft YaHei;
    font-size: 18px;
    margin-top: 3rem;
    margin-bottom: 3rem;
    margin-left: 10rem;
    margin-right: 10rem;
    line-height: 20px;
    text-shadow: 0.2px 0.2px, 0.2px 0.2px, 0.2px 0.2px;
}

.deposit-comment-button,
.deposit-comment-button:active,
.deposit-comment-button:focus {
    background-color: #f92d2d;
    text-align: center;
    color: white;
    border-radius: 15px;
    border: #f92d2d solid 1px;
    font-size: 14px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
}
